<template>
	<section class="container">
		<div class="row mt-5">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.group') }}</b>
				</h3>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<div class="row">
				<div class="col-12">
					<div class="row">
						<div class="col-12">
							<company-group v-model="company.groups"></company-group>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<hr />
				</div>
			</div>

			<div class="row">
				<div class="col-3">
					<BaseActionButton
						class="w-100 btn-update"
						v-if="companyHasGroup && $can('update', 'Company', 'Groups')"
						v-bind:clicked="clicked"
						v-bind:disabled="disabled || clicked"
						type="submit"
					>
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>
<script>
import CompanyGroup from '@/modules/general/company/components/CompanyGroup'
export default {
	name: 'CompanyGroup',
	components: {
		'company-group': CompanyGroup,
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			disabled: false,
			clicked: false,
		}
	},
	computed: {
		companyHasGroup() {
			return this.company?.groups?.length > 0 ?? false
		},
		id() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			var data = this.company
			var id = this.id
			try {
				var result = await this.$store.dispatch('companyVuex/updateCompany', {
					data,
					id,
				})
				this.clicked = false
				this.toast('Success', 'Selskabet er nu opdateret', true)
			} catch (error) {
				this.clicked = false
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
