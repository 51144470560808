<template>
	<section>
		<div class="row">
			<div class="col-12 mb-1" :class="{ 'col-lg-4': !hideBtns }">
				<select class="form-control mx-auto" @change="onChange" v-if="inputValue.length > 0" ref="selector">
					<option v-for="(obj, index) in inputValue" :key="index" :value="index" :selected="index === 0">
						{{ obj.name }}
					</option>
				</select>
			</div>

			<template v-if="!hideBtns">
				<div class="col-auto">
					<b-button @click="editGroup" class="btn-edit" :disabled="disabled" v-if="value.length > 0" v-show="$can('update', 'Company')">
						{{ $t('company.editGroup') }}</b-button
					>
				</div>

				<div class="col-auto">
					<b-button @click="addGroup" class="btn-add" :disabled="disabled" v-show="$can('update', 'Company')"> {{ $t('company.addGroup') }}</b-button>
				</div>
				<div class="col-auto">
					<b-button @click="deleteGroup" class="btn-delete" :disabled="disabled" v-show="$can('update', 'Company')">
						{{ $t('company.deleteGroup') }}</b-button
					>
				</div>
			</template>
		</div>
	</section>
</template>
<script>
import { Group as GroupModel } from '@/models/sub-models/company/group'
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'CompanyGroup',
	props: {
		value: Array,
		hideBtns: Boolean,
		disabled: Boolean,
	},
	mounted() {},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
	methods: {
		async addGroup() {
			const { value: groupName } = await this.$swal.fire({
				title: 'Indsæt gruppenavn',
				input: 'text',
				inputValidator: (value) => {
					if (!value) {
						return 'You need to write something!'
					}
				},
			})
			var group = new GroupModel({
				name: groupName,
				id: uuidv4(),
			})
			this.value.push(group)
		},
		async deleteGroup() {
			var currentGroupIndex = this.$refs.selector.value
			var currentGroupName = this.value[currentGroupIndex].name
			var groupId = this.value[currentGroupIndex].id
			var companyId = this.companyId
			this.$swal
				.fire({
					title: 'Slette gruppen?',
					text: 'Vil du slette denne gruppe (' + currentGroupName + ')?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja slet!',
					showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {
							var result = await this.$store.dispatch('companyVuex/deleteGroup', { companyId, groupId })
							return result
						} catch (error) {
							console.log(error)
							this.$swal.showValidationMessage(`Request failed: ${error.message}`)
						}
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.toast('Success', 'Gruppen er blevet slettet', true)
						this.clicked = false
						this.$router.go()
					}
				})
		},
		onChange(event) {
			this.$emit('indexChanged', event.target.selectedIndex)
		},
		async editGroup() {
			var currentGroupIndex = this.$refs.selector.value
			var currentGroupName = this.value[currentGroupIndex].name
			const { value: newGroupName } = await this.$swal.fire({
				title: 'Ændr gruppenavn',
				input: 'text',
				inputValue: currentGroupName,
				inputValidator: (value) => {
					if (!value) {
						return 'You need to write something!'
					}
				},
			})
			var currentGroup = this.inputValue.find((item) => {
				return item.name == currentGroupName
			})
			currentGroup.name = newGroupName
		},
	},
}
</script>
<style lang="scss"></style>
